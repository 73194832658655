import React from "react"
import { ParallaxProvider } from "react-scroll-parallax"
import { graphql, PageProps } from "gatsby"
import LayoutContainer from "@containers/Layout"
import SEO from "@components/SEO"
import NotFoundSection from "@components/NotFound"
import { NotFoundLoloDataType } from "@typings/requests/404"

const NotFoundPage: React.FC<PageProps<NotFoundLoloDataType>> = props => {
  const {
    allStrapiNotFoundLoloPage: {
      nodes: [loloNotFoundData],
    },
    allStrapiPageFooter: {
      nodes: [footerData],
    },
  } = props.data
  return (
    <>
      <SEO title="404: Not found" lang="en" />
      <ParallaxProvider>
        <LayoutContainer
          className="not-found-page"
          location={props.location}
          lang="en"
        >
          <NotFoundSection
            {...loloNotFoundData}
            image={
              loloNotFoundData.image.localFile.childImageSharp.gatsbyImageData
            }
            footerData={footerData}
          />
        </LayoutContainer>
      </ParallaxProvider>
    </>
  )
}

export default NotFoundPage

export const query = graphql`
  {
    allStrapiNotFoundLoloPage(filter: { locale: { eq: "en" } }) {
      nodes {
        title
        description
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FIXED, width: 1096)
            }
          }
        }
        errorButtonsRedirect: buttons {
          label
          url
          id
        }
      }
    }
    allStrapiPageFooter(filter: { locale: { eq: "en" } }) {
      nodes {
        title
        socialLink {
          name
          title
          url
        }
      }
    }
  }
`
